import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
    Counterparty,
    CounterpartyType,
    useCounterpartyAsyncAutocompleteOptions,
} from 'entities/counterparties/model'
import { Booking, useGetBookingsListQuery } from 'entities/bookings/model'
import { OrderBooking } from 'entities/orders/model'

import {
    selectActiveBookingId,
    selectBookingCounterpartyId,
    selectBookingCounterpartyName,
    selectBookingOrderId,
    selectBookings,
    selectTimeFilter,
} from '../../../Booking/model/slices'
import { CalendarEvent } from '../../../ReactBigCalendar/ReactBigCalendar'

const createAlreadyCreatedBookingsEvents = (
    booking: Booking,
    currentFormOrderId: number | null,
    activeBookingId: number | null,
): CalendarEvent => {
    const counterpartyFullName = booking.counterparty.fullName.split(' ')

    const surname = counterpartyFullName?.[1]
    const firstName = counterpartyFullName?.[0]
    const patronymic = counterpartyFullName?.[2]

    const counterpartyName = [
        surname ?? '',
        firstName ? firstName.split('')[0] + '.' : '',
        patronymic ? patronymic.split('')[0] + '.' : '',
    ].join(' ')

    return {
        id: booking.id,
        comment: booking?.comment || null,
        orderId: booking.orderId,
        title: <div>{booking.area.name}</div>,
        counterpartyName,
        counterpartyPhone: booking.counterparty.phone,
        isCompany: booking.counterparty.type === CounterpartyType.Company,
        start: booking.startTime ?? new Date(),
        end: booking.endTime ?? new Date(),
        resourceId: booking.area.id,
        resourceName: booking.area.name ?? '',
        isNew: false,
        isEdit: activeBookingId
            ? activeBookingId === booking.id
            : currentFormOrderId === booking.orderId,
    }
}

const createCurrentFormBookingsEvents = (
    booking: OrderBooking,
    currentFormOrderId: number | null,
    activeBookingId: number | null,
    isNew: boolean,
    currentFormCounterparty?: Counterparty,
): CalendarEvent => {
    const contact = currentFormCounterparty?.contact
    const isCompany = currentFormCounterparty?.type === CounterpartyType.Company

    const personName = contact
        ? [
              contact?.surname ?? '',
              contact?.firstName ? contact.firstName.split('')[0] + '.' : '',
              contact?.patronymic ? contact.patronymic.split('')[0] + '.' : '',
          ].join(' ')
        : null
    const companyName =
        currentFormCounterparty?.additionalData?.documents?.organizationName ??
        ''
    const counterpartyName = isCompany ? companyName : personName

    return {
        id: booking?.id,
        comment: booking?.comment || null,
        orderId: currentFormOrderId,
        title: <div>{booking.areaName}</div>,
        counterpartyName,
        counterpartyPhone: currentFormCounterparty?.phone ?? null,
        isCompany,
        start: booking.startTime ?? new Date(),
        end: booking.endTime ?? new Date(),
        resourceId: booking.areaId,
        resourceName: booking.areaName ?? '',
        isNew,
        isEdit: activeBookingId === booking.id,
    }
}

export const useBuildCalendarEvents = () => {
    const timeFilter = useSelector(selectTimeFilter)
    const currentFormCounterpartyId = useSelector(selectBookingCounterpartyId)
    const currentFormCounterpartyName = useSelector(
        selectBookingCounterpartyName,
    )

    const { counterparties } = useCounterpartyAsyncAutocompleteOptions({
        search_param: currentFormCounterpartyName ?? undefined,
    })
    const currentFormOrderId = useSelector(selectBookingOrderId)
    const currentFormCounterparty = counterparties?.find(
        counterparty => counterparty.id === currentFormCounterpartyId,
    )

    const activeBookingId = useSelector(selectActiveBookingId)
    const { bookings: alreadyCreatedBookings } =
        useGetBookingsListQuery(timeFilter)
    const alreadyCreatedBookingsEvents = useMemo(
        () =>
            alreadyCreatedBookings.map(booking =>
                createAlreadyCreatedBookingsEvents(
                    booking,
                    currentFormOrderId,
                    activeBookingId,
                ),
            ),
        [alreadyCreatedBookings, activeBookingId, currentFormOrderId],
    )

    const currentFormBookings = useSelector(selectBookings)
    const currentFormBookingsEvents = useMemo(
        () =>
            currentFormBookings.map(booking =>
                createCurrentFormBookingsEvents(
                    booking,
                    currentFormOrderId,
                    activeBookingId,
                    !alreadyCreatedBookings.find(bkg => bkg.id === booking.id),
                    currentFormCounterparty,
                ),
            ),
        [
            alreadyCreatedBookings,
            currentFormBookings,
            currentFormOrderId,
            currentFormCounterparty,
            activeBookingId,
        ],
    )

    const bookingEvents = useMemo(
        () =>
            Array.from(
                new Map(
                    [
                        ...alreadyCreatedBookingsEvents,
                        ...currentFormBookingsEvents,
                    ].map(event => [event.id, event]),
                ).values(),
            ),
        [alreadyCreatedBookingsEvents, currentFormBookingsEvents],
    )

    return {
        alreadyCreatedBookingsEvents,
        currentFormBookingsEvents,
        bookingEvents,
    }
}
