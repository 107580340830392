import moment from 'moment'
import { OrderBooking } from 'entities/orders/model'

export const getBookingRange = (booking: OrderBooking) => {
    if (!booking?.startTime || !booking?.endTime) {
        return ''
    }

    const start = moment(booking.startTime)
    const end = moment(booking.endTime)
    const duration = moment.duration(end.diff(start))
    const diff = duration.hours()

    if (diff < 1) {
        const diffMinutes = duration.minutes()
        return `${diffMinutes} минут`
    }

    if (diff === 1) {
        return `${diff} час`
    }

    return `${diff} часа`
}
