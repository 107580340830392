import { Checkbox } from "shared/components/Checkbox"
import { SettingNotificationsRow, SettingWrapperNotifications } from "./style"
import { useState } from "react"

const notificationList = ["Уведомления", "Акции", "Отмена брони", "Освободилось время", "Новое бронирование", "Изменение бронирования"];

export const SettingNotifications = () => {
    const [checked, setChecked] = useState(false);

    const onChange = () => {
        setChecked(!checked)
    }

    return (
        <SettingWrapperNotifications>
            <div className="title">Настройки уведомлений</div>
            <SettingNotificationsRow>
                {notificationList.map((item, index) => (
                    <div key={index} className="notificationBlock">
                        <div className="notificationBlock__title">{item}</div>
                        <div className="notificationBlock__checkbox-list">
                            <label>
                                Email
                                <Checkbox checked={checked} onChange={onChange} />
                            </label>
                            <label>
                                Телефон
                                <Checkbox checked={checked} onChange={onChange} />
                            </label>
                        </div>
                    </div>
                ))}
                <div className="notificationBlock">
                    <div className="notificationBlock__title">Не уведомлять</div>
                    <div className="notificationBlock__checkbox-list">
                        <label>
                            <Checkbox checked={checked} onChange={onChange} />
                        </label>
                    </div>
                </div>
            </SettingNotificationsRow>
        </SettingWrapperNotifications>
    )
}