export const ProviderData = [
    [
        { 
            name: "id", 
            label: "Номер документа", 
            // placeholder: "№65184189", 
            disabled: true, 
            type: "text",
        },
        { 
            name: "date", 
            label: "Дата", 
            placeholder: "до 28.08.2023", 
            disabled: true, 
            type: "text",
        },
    ],
    [
        { 
            name: "inn_name", 
            label: "Наименование или ИНН", 
            placeholder: "Наименование или ИНН", 
            disabled: false, 
            type: "text",
        },
    ],
    [
        { 
            name: "inn", 
            label: "ИНН", 
            placeholder: "Введите ИНН", 
            disabled: false, 
            type: "text",
        },
        { 
            name: "kpp", 
            label: "КПП", 
            placeholder: "Введите КПП", 
            disabled: false, 
            type: "text",
        },
    ],
    [
        { 
            name: "address", 
            label: "ИНН", 
            placeholder: "Введите адрес", 
            disabled: false, 
            type: "text",
        },
    ],
    [
        { 
            name: "bik", 
            label: "БИК банка", 
            placeholder: "Введите БИК", 
            disabled: false, 
            type: "text",
        },
        { 
            name: "bank", 
            label: "Банк", 
            placeholder: "Введите название банка", 
            disabled: false, 
            type: "text",
        },
    ],
    [
        { 
            name: "tas1", 
            label: "Расчетный счет", 
            placeholder: "Номер расчетного счета", 
            disabled: false, 
            type: "text",
        },
    ],
    [
        { 
            name: "ras", 
            label: "Кореспонденский счет", 
            placeholder: "Номер расчетного счета", 
            disabled: false, 
            type: "text",
        },
    ],
]

export const Counterparty = [
    [
        { 
            name: "inn_name", 
            label: "Наименование или ИНН", 
            placeholder: "Наименование или ИНН", 
            disabled: false, 
            type: "text",
        },
    ],
    [
        { 
            name: "inn", 
            label: "ИНН", 
            placeholder: "Введите ИНН", 
            disabled: false, 
            type: "text",
        },
        { 
            name: "kpp", 
            label: "КПП", 
            placeholder: "Введите КПП", 
            disabled: false, 
            type: "text",
        },
    ],
    [
        {
            name: "adress", 
            label: "Юридический адрес получателя", 
            placeholder: "Введите адрес", 
            disabled: false, 
            type: "text",
        }
    ],
    [
        {
            name: "comment", 
            label: "Основание", 
            placeholder: "Напишите основание: Договор №1 от 28.08.2023", 
            disabled: false, 
            type: "text",
        }
    ]
]