import styled from "styled-components";

export const StyledCreateCertificate = styled.div`
    .container {
        margin-bottom: 10px;
        border-bottom: 1px solid #8181A5;
        padding-bottom: 20px;
    }
    .wrapperSerteficate {
        .serteficateNumber {
            flex: none!important;
            max-width: 101px
        }
        .serteficateDate {
            flex: none!important;
            max-width: 95px;
        }
        .serteficateSumm {
            flex: none!important;
            max-width: 104px;
        }
    }
    .editButton {
        display: flex;
        gap: 8px;
        align-items: center;
        .icon-add {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #5E81F4;
            border-radius: 8px;
        }
    }
`

export const StyledCertificateModal = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: white url("/bg.png") center / cover no-repeat;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledCertificateBlock = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
`;

export const StyledCertificateContent= styled.div`
    border-radius: 16px;
    width: 100%;
    min-width: 1000px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    background: #fff;
    padding: 42px 100px;
    color: #000;
    .CertificateContentTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        span {
            font-size: 16px;
        }
    }
    .CertificateContentBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            font-size: 16px;
        }
    }
    .title {
        font-size: 48px;
        margin-bottom: 31px;
        position: relative;
        img {
            position: absolute;
            right: 80px;
            width: 85px;
            height: 85px;
            top: 0;
        }
        span {
            display: block;
            margin-top: 5px;
            font-size: 16px;
        }
    }
    .CertificateContentInfo {
        margin-bottom: 30px;
        span {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
        }
        hr {
            margin: 20px 0;
            width: 44px;
            height: 1px;
            border: none;
            background-color: #000;
        }
        .CertificateContentInfoTitle {
            font-size: 48px;
        }
    }
`;

export const StyledCertificateModalButton = styled.div`
    width: 49px;
    height: 39px;
    background-color: #FFFFFF;
    border: 1px solid #E1E4EA;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`