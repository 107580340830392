import { CheckoutPayments, DetailsPaymentsForm } from "features/DetailsPayments"
import { StyledWrapperDetails } from "./style"
import { useGetDetailOrderQuery } from "entities/orders/model"
import { useParams } from "react-router-dom"
import { Loader } from "shared/components/Loader"

export const DetailsPaymentsPage = () => {

    const {id} = useParams();

    const { detailOrder, isLoading, isSuccess } = useGetDetailOrderQuery(Number(id) ?? null)


    if(isLoading) {
        return (
            <Loader />
        )
    }

    return (    
        <StyledWrapperDetails>
            {isSuccess && detailOrder && (
                    <>
                    hello
                        <DetailsPaymentsForm detailOrder={detailOrder} />
                        <CheckoutPayments detailOrder={detailOrder} />
                    </>
                )
            }
        </StyledWrapperDetails>
    )
}