import { useMemo } from 'react'
import { SlotInfo } from 'react-big-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop'
import { useNavigate } from 'react-router-dom'

import { BookingsApi } from 'entities/bookings/api'

import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { useGetBookingsListQuery } from 'entities/bookings/model'

import { openSidebar, SideBar } from 'shared/redux/slice/sidebar'

import {
    ActiveBookingFormTab,
    addBooking,
    BookingMode,
    editBooking,
    OrderViewMode,
    selectActiveBookingId,
    selectBookingFormOrderViewMode,
    selectBookingIdToEdit,
    selectBookingMode,
    selectBookingOrderId,
    selectBookings,
    selectOrderEditStateInitialising,
    selectTimeFilter,
    setActiveBookingId,
    setBookingActiveTab,
    setBookingFormMode,
    setBookingIdToEdit,
    setBookingOrderId,
    setToday,
} from '../../../Booking/model/slices'
import { CalendarEvent } from '../../../ReactBigCalendar/ReactBigCalendar'
import { useFillStore } from '../../../Booking/model/hooks'
import { ViewMode, ViewModeType } from './useCalendarController'
import moment from 'moment'

export const useCalendarActions = (view: ViewModeType) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const mode = useSelector(selectBookingMode)
    const isEditStateInitialising = useSelector(
        selectOrderEditStateInitialising,
    )

    const activeBookingOrderId = useSelector(selectBookingOrderId)
    const updateBookings = useFillStore()
    const { areasResources } = useAreasResourcesQuery()
    const timeFilter = useSelector(selectTimeFilter)

    const activeBookingId = useSelector(selectActiveBookingId)
    const bookings = useSelector(selectBookings)
    const activeBooking = useMemo(
        () => bookings.find(a => a.id === activeBookingId),
        [bookings, activeBookingId],
    )

    const previewBookingId = useSelector(selectBookingIdToEdit)
    const orderViewMode = useSelector(selectBookingFormOrderViewMode)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const isPreview = useMemo(
        () =>
            isEdit &&
            orderViewMode === OrderViewMode.Preview &&
            !!previewBookingId,
        [orderViewMode, isEdit, previewBookingId],
    )

    const { refetch: refetchAlreadyCreatedBookings } =
        useGetBookingsListQuery(timeFilter)

    const onSelectSlot = (props: SlotInfo) => {
        if (mode === BookingMode.Edit && isEditStateInitialising) {
            return
        }
        if (mode === BookingMode.Edit && isPreview) {
            return
        }
        const startTime = new Date(props.start)
        const endTime =
            props.action === 'click'
                ? new Date(
                      new Date(props.start).setHours(startTime.getHours() + 1),
                  )
                : new Date(props.end)
        const areaId = Number(props.resourceId)
        const areaName = props?.resourceId
            ? (areasResources?.find(res => res.id === areaId)?.title ?? '')
            : ''

        if (
            activeBooking &&
            !activeBooking.areaId &&
            (!activeBooking.startTime || !activeBooking.endTime)
        ) {
            dispatch(
                editBooking({
                    bookingId: activeBooking.id,
                    body: {
                        areaId,
                        areaName,
                        startTime,
                        endTime,
                    },
                }),
            )
            if (view === ViewMode.Month) {
                navigate('/calendar/day')
            }
            return
        }

        const bookingId = new Date().getMilliseconds() ** 2
        dispatch(
            addBooking({
                id: bookingId,
                areaPrice: 0,
                comment: '',
                nomenclatures: [],
                areaAbsolutDiscount: null,
                areaPercentDiscount: null,
                nomenclaturePrice: 0,
                nomenclaturePriceWithDiscount: 0,
                totalPrice: 0,
                totalPriceWithDiscount: 0,
                membersCount: null,
                areaId,
                areaName,
                startTime,
                endTime,
            }),
        )
        dispatch(setActiveBookingId({ bookingId }))
        dispatch(openSidebar(SideBar.Booking))
        dispatch(setBookingActiveTab({ tab: ActiveBookingFormTab.Booking }))
        if (view === ViewMode.Month) {
            navigate('/calendar/day')
        }
    }

    const updateBookingByCalendarAction = async (
        props: EventInteractionArgs<CalendarEvent>,
    ) => {
        if (mode === BookingMode.Edit && isPreview) {
            return
        }
        const event = props.event
        const areaId = Number(props.resourceId)
        const areaName = props.resourceId
            ? (areasResources?.find(res => res.id === areaId)?.title ?? null)
            : null
        const startTime = new Date(props.start)
        const endTime = new Date(props.end)
        const bookingId = event.id
        const orderId = event.orderId

        if (bookingId) {
            dispatch(
                editBooking({
                    bookingId,
                    body: { startTime, endTime, areaId, areaName },
                }),
            )
        }

        if (!orderId || !bookingId || event.isNew) {
            return
        }

        await BookingsApi.updateBookings(
            { orderId, bookingId },
            { startTime, endTime, areaId },
        )
        await refetchAlreadyCreatedBookings()
    }

    const onDropSlot = async (props: EventInteractionArgs<CalendarEvent>) => {
        await updateBookingByCalendarAction(props)
    }

    const onEventResize = async (
        props: EventInteractionArgs<CalendarEvent>,
    ) => {
        await updateBookingByCalendarAction(props)
    }

    const onClickEvent = (props: EventInteractionArgs<CalendarEvent>) => {
        const orderId = props.event.orderId
        const id = props.event.id
        const today = moment(props.event.start)

        const handleEditBooking = () => {
            if (orderId && id && id !== activeBookingOrderId) {
                dispatch(setBookingIdToEdit({ bookingIdToEdit: id }))
                dispatch(setBookingOrderId({ orderId }))
                dispatch(setBookingFormMode({ mode: BookingMode.Edit }))
                updateBookings(orderId, id).then()
            }
        }

        const setActiveBooking = () => {
            if (id) {
                dispatch(setActiveBookingId({ bookingId: id }))
            }
        }

        if (view === ViewMode.Month) {
            dispatch(openSidebar(SideBar.Booking))
            if (today) {
                dispatch(setToday({ today: today.toDate().toISOString() }))
            }
            setActiveBooking()
            if (!props.event.isNew) {
                handleEditBooking()
                navigate('/calendar/day')
                return
            }
            navigate('/calendar/day')
            return
        }

        dispatch(openSidebar(SideBar.Booking))

        setActiveBooking()

        if (mode === BookingMode.Edit && !isPreview) {
            return
        }

        if (!props.event.isNew) {
            handleEditBooking()
        }
    }

    return {
        onSelectSlot,
        onDropSlot,
        onEventResize,
        onClickEvent,
    }
}
