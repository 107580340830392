import styled from "styled-components";

export const StyledNotifications = styled.div`
    padding: 10px;
    margin-bottom: 4px;
    transition: all 0.2s;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        border-radius: 8px;
        border-right: 2px solid #5E81F4;
        background: #F7FBFE;
    }
    &:active {
        background-color: #E4EEF5;
        transition: all 0.2s;
    }
`;

export const StyledNotificationsWrapper = styled.div`
    display: flex;
    gap: 10px;

`;

export const StyledNotificationsIcon = styled.div`
    border-radius: 8px;
    background: rgba(94, 129, 244, 0.20);
    width: 100%;
    max-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledNotificationsContent = styled.div`
    position: relative;
    width: 100%;
    .title-card {
        color: #282A42;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }
    .notifications-text {
        color: #8181A5;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
    }
    .information {

    }
    .comments {

    }
    .status {

    }
    .time {
        font-size: 12px;
    }
    .payment {
        position: absolute;
        right: 0;
        bottom: 0;
    }
`;

export const StyledFormBooking = styled.div`
    
`;