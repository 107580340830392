import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { CounterpartiesApi } from '../../api'
import { QueryKeys } from './constants'
import { Counterparty } from '../index'

export const useGetCounterpartyByIdQuery = (
    id: number | null,
    options?: UseQueryOptions,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: null,
            queryKey: [QueryKeys.GetAllCounterpartiesQuery, id],
            queryFn: id
                ? () => CounterpartiesApi.getCounterpartyById(id)
                : () => null,
            ...(options ?? {}),
        })

    return {
        counterparty: data as Counterparty,
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
