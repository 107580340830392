import React from 'react'
import { Icon } from '../Icons'
import { StyledButton } from './style'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode
    themeType?: 'primary' | 'outline' | 'item' | 'outline-2' | 'delete'
    beforeIcon?: string
    afterIcon?: string
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
    loading?: boolean
    bgLoading?: string
    padding?: number
}

export const Button: React.FC<ButtonProps> = ({
    children,
    themeType = 'primary',
    padding,
    beforeIcon,
    afterIcon,
    loading = false,
    bgLoading = '#5E81F4',
    ...props
}) => {
    return (
        <StyledButton
            style={{
                ...(props.disabled
                    ? { opacity: 0.5, cursor: 'not-allowed' }
                    : {}),
            }}
            className='styledButton'
            themeType={themeType}
            padding={padding}
            {...props}
        >
            {beforeIcon && <Icon name={beforeIcon} />}
            {loading ? (
                <div className='loader' style={{ background: bgLoading }}></div>
            ) : (
                <span>{children}</span>
            )}
            {afterIcon && <Icon name={afterIcon} />}
        </StyledButton>
    )
}
