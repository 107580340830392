const SiteIcon = () => (
    <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='24' cy='24' r='20' fill='#5E81F4' />
        <g clip-path='url(#clip0_364_17757)'>
            <path
                d='M24.0781 37.1562C31.2922 37.1562 37.1562 31.2922 37.1562 24.0781C37.1562 16.8641 31.2922 11 24.0781 11C16.8641 11 11 16.8641 11 24.0781C11 31.2922 16.8641 37.1562 24.0781 37.1562ZM13.9531 29.2883L19.2688 29.2461C19.8594 31.3766 20.8508 33.4437 22.2008 35.3C18.5937 34.7094 15.5773 32.4102 13.9531 29.2883ZM20.6187 20.4922H27.2422C27.7273 22.8125 27.7273 25.1961 27.2844 27.5164L20.6187 27.5586C20.1336 25.2172 20.1336 22.8125 20.6187 20.4922ZM23.9305 34.8148C22.6016 33.1063 21.6312 31.2289 21.0406 29.2461L26.8414 29.2039C26.2297 31.1867 25.2594 33.0852 23.9305 34.8148ZM25.618 35.3633C27.0102 33.4648 28.0016 31.3766 28.5922 29.1828L34.2664 29.1406C32.6211 32.4523 29.4148 34.8359 25.618 35.3633ZM35.4688 24.0781C35.4688 25.2594 35.2789 26.3984 34.9625 27.4531L28.993 27.4953C29.4148 25.175 29.3937 22.8125 28.9508 20.4922H34.8781C35.2578 21.6102 35.4688 22.8336 35.4688 24.0781ZM34.1609 18.8047H28.55C27.9383 16.6953 26.968 14.6492 25.618 12.793C29.3516 13.2992 32.4945 15.5984 34.1609 18.8047ZM26.7992 18.8047H21.0617C21.6734 16.8641 22.6227 15.0289 23.9305 13.3414C25.2383 15.0289 26.1875 16.8641 26.7992 18.8047ZM22.2008 12.8563C20.8719 14.6914 19.9016 16.6953 19.3109 18.8047H13.9953C15.6195 15.7039 18.6148 13.4469 22.2008 12.8563ZM18.9102 20.4922C18.4672 22.8336 18.4672 25.2172 18.8891 27.5586L13.257 27.6008C12.8773 26.4828 12.6875 25.3016 12.6875 24.0781C12.6875 22.8336 12.8984 21.6102 13.2781 20.4922H18.9102Z'
                fill='white'
            />
        </g>
        <defs>
            <clipPath id='clip0_364_17757'>
                <rect
                    width='27'
                    height='27'
                    fill='white'
                    transform='translate(11 11)'
                />
            </clipPath>
        </defs>
    </svg>
)

export default SiteIcon
