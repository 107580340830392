import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { QueryKeys } from 'entities/payments/model/hooks/constants'
import { PaymentApi } from 'entities/payments/api'
import { Payment } from 'entities/payments/model'

export const useGetPaymentsByOrderIdQuery = (
    orderId: number | null,
    options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>,
) => {
    const { data, error, isError, isLoading, isSuccess, refetch, status } =
        useQuery({
            initialData: [],
            queryKey: [QueryKeys.GetPayments, orderId],
            queryFn: orderId
                ? () => PaymentApi.getPaymentsByOrderId(orderId)
                : () => {},
            enabled: !!orderId,
            ...(options ?? {}),
        })

    return {
        payments: data as Payment[],
        error,
        status,
        refetch,
        isSuccess,
        isLoading,
        isError,
    }
}
