import { FC } from 'react'

import {
    Payment,
    PaymentMethodLabels,
    PaymentStatusLabels,
} from 'entities/payments/model'
import { Line } from 'shared/components/Line'

import {
    StyledTable,
    StyledTableCell,
    StyledTableHeader,
    StyledTableRow,
} from './styles'

const InvoiceDetailTableColumns = [
    { key: 'date', title: 'Дата', textAlign: 'left' },
    { key: 'paymentWay', title: 'Способ оплаты', textAlign: 'center' },
    { key: 'total', title: 'Сумма', textAlign: 'center' },
    { key: 'paymentStatus', title: 'Способ оплаты', textAlign: 'center' },
]

interface InvoicePaymentsTableProps {
    payments: Payment[]
}

export const InvoicePaymentsTable: FC<InvoicePaymentsTableProps> = ({ payments }) => (
    <StyledTable>
        <thead>
            <tr>
                {InvoiceDetailTableColumns.map(column => (
                    <StyledTableHeader
                        key={column.key}
                        style={{
                            textAlign: column.textAlign as any,
                        }}
                    >
                        {column.title}
                    </StyledTableHeader>
                ))}
            </tr>
        </thead>
        <tbody>{renderRows(payments)}</tbody>
    </StyledTable>
)

const renderRows = (payments: Payment[]) => {
    return payments.map(payment => (
        <>
            <StyledTableRow>
                <StyledTableCell>{payment.paymentUrl}</StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    {PaymentMethodLabels[payment.method.type]}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    {PaymentMethodLabels[payment.method.type]}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    ₽{payment.amount}
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                    {PaymentStatusLabels[payment.status]}
                </StyledTableCell>
            </StyledTableRow>

            <tr>
                <StyledTableCell colSpan={InvoiceDetailTableColumns.length}>
                    <Line />
                </StyledTableCell>
            </tr>
        </>
    ))
}
