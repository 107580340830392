import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import React, { memo, useMemo } from 'react'
import moment, { Moment } from 'moment'
import { Calendar, momentLocalizer, View } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import {
    WrapperCalendar,
    StyledEditEventsWrapper,
    StyledNewEventsWrapper,
    StyledReadyEventsWrapper,
    StyledMouthEventsWrapper,
} from './style'

export interface CalendarEvent {
    allDay?: boolean
    id?: number | null
    orderId: number | null
    counterpartyName: string | null
    counterpartyPhone: string | null
    comment: string | null
    isCompany: boolean
    title: React.ReactNode | null
    start: Date | null
    end: Date | null
    resourceId: number | null
    resourceName: string
    isNew: boolean
    isEdit: boolean
}

// TODO: fix types
interface IPropsReactBigCalendar {
    views: any
    defaultView: View
    min?: Date
    max?: Date
    currentDate: Moment
    onDateChange: (date: Moment) => void
    events?: CalendarEvent[] | undefined
    resources?: { id: number; title: string }[]
    components?: any
    onEventResize?: (props: any) => void
    onDropSlot?: (props: any) => void
    onSelectSlot?: (props: any) => void
    onClickEvent?: (props: any) => void
}

const DnDCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment)

const ReactBigCalendarComponent = ({
    views,
    defaultView,
    min,
    max,
    currentDate,
    onDateChange,
    events,
    resources,
    components,
    onDropSlot,
    onEventResize,
    onSelectSlot,
    onClickEvent,
}: IPropsReactBigCalendar) => {
    const { filteredEvents, totalFilteredCount } = useMemo(() => {
        if (defaultView !== 'month') {
            return { filteredEvents: events, totalFilteredCount: null }
        }
        const groupedEvents = events?.reduce(
            (acc, event) => {
                if (event.start) {
                    const dateKey = event.start.toISOString().split('T')[0]
                    if (!acc[dateKey]) {
                        acc[dateKey] = []
                    }
                    acc[dateKey].push(event)
                }
                return acc
            },
            {} as Record<string, typeof events>,
        )

        let totalFilteredCount = 0
        const filteredEvents = Object.entries(groupedEvents || {}).flatMap(
            ([, dayEvents]) => {
                if (dayEvents.length > 4) {
                    totalFilteredCount += dayEvents.length - 4

                    return dayEvents
                        .slice(0, 4)
                        .sort((a, b) => {
                            if (a.start === null && b.start === null) return 0

                            if (a.start === null) return 1
                            if (b.start === null) return -1

                            return a.start.getTime() - b.start.getTime()
                        })
                        .map((event, index, slicedEvents) => {
                            if (index === slicedEvents.length - 1) {
                                return { ...event, isLast: true }
                            }
                            return event
                        })
                }

                return dayEvents
            },
        )

        return {
            filteredEvents,
            totalFilteredCount,
        }
    }, [events])

    const eventsWrapper = ({ event, children }: any) => {
        if (defaultView === 'month') {
            return (
                <StyledMouthEventsWrapper>
                    {children}
                    {event.isLast ? (
                        <span
                            style={{
                                fontSize: 12,
                                position: 'absolute',
                                zIndex: 1,
                                color: '#34bd93',
                            }}
                        >
                            еще {totalFilteredCount}
                        </span>
                    ) : null}
                </StyledMouthEventsWrapper>
            )
        }
        if (event.isEdit) {
            return <StyledEditEventsWrapper>{children}</StyledEditEventsWrapper>
        }

        if (event.isNew) {
            return <StyledNewEventsWrapper>{children}</StyledNewEventsWrapper>
        }

        return <StyledReadyEventsWrapper>{children}</StyledReadyEventsWrapper>
    }

    const event = ({ event }: { event: CalendarEvent }) => {
        if (defaultView === 'month') {
            if (event.resourceName) {
                return (
                    <span style={{ fontSize: 12 }}>
                        {event.resourceName ?? ''}
                    </span>
                )
            }
            return null
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    paddingTop: 10,
                }}
            >
                {event.counterpartyName ? (
                    <span style={{ fontSize: 12 }}>
                        {event.counterpartyName ?? ''}
                    </span>
                ) : (
                    <></>
                )}
                {event.counterpartyPhone ? (
                    <span style={{ fontSize: 12 }}>
                        {event.counterpartyPhone ?? ''}
                    </span>
                ) : (
                    <></>
                )}
                {event.comment && event.comment?.length ? (
                    <span style={{ fontSize: 12 }}>{event.comment ?? ''}</span>
                ) : (
                    <></>
                )}
            </div>
        )
    }

    return (
        <WrapperCalendar>
            <DnDCalendar
                onDragStart={onClickEvent}
                onEventResize={onEventResize}
                onEventDrop={onDropSlot}
                onSelectSlot={onSelectSlot}
                localizer={localizer}
                views={views}
                defaultView={defaultView}
                timeslots={1}
                step={60}
                min={min ?? moment().startOf('day').toDate()}
                max={max ?? moment().endOf('day').toDate()}
                date={currentDate.toDate()}
                onNavigate={date => onDateChange(moment(date))}
                toolbar={false}
                events={filteredEvents}
                resources={resources}
                components={{
                    eventWrapper: eventsWrapper,
                    event,
                    ...components,
                }}
                tooltipAccessor={null}
                enableAutoScroll={false}
                selectable
            />
        </WrapperCalendar>
    )
}

export const ReactBigCalendar = memo(ReactBigCalendarComponent)
