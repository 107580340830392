import { axiosApi } from 'shared/api/axios'
import { OrderEndpoint } from './constants'

// WIP!

type Response = {
    payment_url: string
}

export const getOrderPaymentUrl = async ({
    orderId,
    redirectUrl,
}: {
    orderId: number
    redirectUrl: string
}) => {
    try {
        const response = await axiosApi.post<Response>(
            `${OrderEndpoint.payment}?order_id=${orderId}&redirect_url=${redirectUrl}`,
        )
        return { paymentUrl: response.data.payment_url }
    } catch (err) {
        console.error(err)
        throw new Error('Ошибка создания заказа')
    }
}
