import { useEffect, useMemo } from 'react'
import moment, { Moment } from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'

import { useAreasResourcesQuery } from 'entities/areas/model/hooks/useAreasResourcesQuery'
import { openSidebar, SideBar } from 'shared/redux/slice/sidebar'
import { useCalendarActions } from './useCalendarActions'
import { useNavigationBlocker } from './useNavigationBlocker'
import { useBuildCalendarEvents } from './useBuildCalendarEvents'
import {
    addEmptyBooking,
    BookingMode,
    clearBookingState,
    OrderViewMode,
    selectBookingFormOrderViewMode,
    selectBookingIdToEdit,
    selectBookingMode,
    selectToday,
    setActiveBookingId,
    setBookingFormMode,
    setToday,
    setView,
} from '../../../Booking/model/slices'

export const ViewMode = {
    Day: 'day',
    Week: 'week',
    Month: 'month',
}

export type ViewModeType = (typeof ViewMode)[keyof typeof ViewMode]

interface UseCalendarControllerParams {
    view: ViewModeType
}

export const useCalendarController = ({
    view,
}: UseCalendarControllerParams) => {
    useNavigationBlocker()
    const dispatch = useDispatch()

    const currentDateString = useSelector(selectToday)
    const currentDate = moment(currentDateString)

    const mode = useSelector(selectBookingMode)
    const previewBookingId = useSelector(selectBookingIdToEdit)
    const orderViewMode = useSelector(selectBookingFormOrderViewMode)
    const isEdit = useMemo(() => mode === BookingMode.Edit, [mode])
    const isPreview = useMemo(
        () =>
            isEdit &&
            orderViewMode === OrderViewMode.Preview &&
            !!previewBookingId,
        [orderViewMode, isEdit, previewBookingId],
    )

    const setCurrentDate = (newDate: Moment) => {
        dispatch(setToday({ today: newDate.toDate().toISOString() }))
    }
    const { areasResources } = useAreasResourcesQuery()

    useEffect(() => {
        dispatch(setView({ view }))
    }, [view])

    const areas = useMemo(() => areasResources, [areasResources])
    const { bookingEvents } = useBuildCalendarEvents()

    const { onSelectSlot, onDropSlot, onEventResize, onClickEvent } =
        useCalendarActions(view)

    const handleDateChange = (date: Moment) => {
        setCurrentDate(date)
    }

    const onNewBookingClick = () => {
        if (mode === BookingMode.Edit && isPreview) {
            dispatch(clearBookingState())
            dispatch(setBookingFormMode({ mode: BookingMode.Create }))
        }
        if (mode === BookingMode.Edit && !isPreview) {
            dispatch(setBookingFormMode({ mode: BookingMode.Create }))
        }
        const bookingId = new Date().getMilliseconds() ** 2
        dispatch(addEmptyBooking({ bookingId }))
        dispatch(setActiveBookingId({ bookingId }))
        dispatch(openSidebar(SideBar.Booking))
    }

    return {
        currentDate,
        areasResources: areas,
        bookingEvents,

        onSelectSlot,
        onDropSlot,
        onClickEvent,
        onEventResize,

        handleDateChange,
        onNewBookingClick,
    }
}
