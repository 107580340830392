import { Flex } from 'shared/components/Flex'
import CompanyImage from 'shared/assets/companyImage.png'
import { SocialLinks } from 'shared/components/SocialLinks'

import {
    StyledCompanyImage,
    StyledSecondaryLinkText,
    StyledSecondaryText,
    StyledSubHeader,
} from './styles'

export const InvoiceSubHeader = () => (
    <StyledSubHeader>
        <Flex gap={10}>
            <StyledCompanyImage src={CompanyImage} />
            <Flex flexDirection='column' justifyContent='space-between'>
                <div>
                    <StyledSecondaryText>
                        ФОТО, ВИДЕОСТУДИЯ:{' '}
                    </StyledSecondaryText>
                    <span>Студия Background</span>
                </div>
                <div>
                    <StyledSecondaryText>Адрес: </StyledSecondaryText>
                    <span>Россия, Москва, ул. Генерала Дорохова, 28А</span>
                </div>
                <div>
                    <StyledSecondaryText>Коткакты: </StyledSecondaryText>
                    <StyledSecondaryLinkText>
                        +7 (985) 199-75-74 info@backg.ru
                    </StyledSecondaryLinkText>
                </div>
            </Flex>
        </Flex>
        <SocialLinks />
    </StyledSubHeader>
)
