import styled from "styled-components";

export const TabsWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    .tabContainer {
        gap: 30px;
        .tabHeaders {
            gap: 30px;
            .tabHeader {
                width: auto;
                font-weight: 500;
                line-height: 20px;
                color: #282A42;
                &.active {
                    color: #282A42;
                }
            }
            .underline {
                border-radius: 2px;
                height: 4px;
                background-color: #5E81F4;
            }
        }
    }
`;

export const WrapperAvatar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;