import React from 'react'

import {
    PaymentWayOptions,
    usePaymentFormController,
} from 'features/Booking/model/hooks/usePaymentFormController'
import { PaymentMethodName } from 'entities/payments/model'
import { Autocomplete } from 'shared/components/Autocomplete'
import { Input } from 'shared/components/Input'
import { Button } from 'shared/components/Button'
import { Label } from 'shared/components/Input/style'

export const PaymentForm = () => {
    const {
        amount,
        onEditAmount,

        certificate,
        certificatesOptions,
        currentCertificateBalance,
        onChangeCertificate,

        paymentWay,
        comment,
        isButtonDisabled,
        isCompany,
        onChangePaymentWay,
        onChangeComment,
        onGoNext,
    } = usePaymentFormController()

    return (
        <div className='content'>
            <Autocomplete
                value={paymentWay ?? null}
                onChange={onChangePaymentWay}
                options={isCompany ? PaymentWayOptions : PaymentWayOptions.filter((item) => item.value !== "invoice")}
                // options={PaymentWayOptions}
                label='Способ оплаты'
                placeholder='Выберете способ оплаты'
                required
            />
            {(paymentWay?.value === PaymentMethodName.Online ||
                paymentWay?.value === PaymentMethodName.Cash) && (
                <Input
                    value={amount}
                    onChange={onEditAmount}
                    label='Сумма'
                    placeholder='Введите сумму'
                    required
                />
            )}
            {paymentWay?.value === PaymentMethodName.Certificate && (
                <>
                    <Autocomplete
                        value={certificate ?? null}
                        onChange={onChangeCertificate}
                        options={certificatesOptions}
                        label='Сертификат'
                        placeholder='Введите название...'
                        required
                    />
                    <Label>
                        Баланс сертификата: ₽{currentCertificateBalance}
                    </Label>
                </>
            )}
            <Input
                label='Комментарий'
                value={comment}
                onChange={onChangeComment}
            />
            <Button disabled={isButtonDisabled} onClick={onGoNext}>
                Далее
            </Button>
        </div>
    )
}
