import { Formik } from "formik"
import { DetailsPaymentsFormStyled, DetailsPaymentsTitle } from "./style"
import { Accordion } from "shared/components/Accordion"
import { Counterparty, ProviderData } from "../model/formData"
import { FormikInput } from "shared/components/FormikInput"
import { Button } from "shared/components/Button"
import { IPropsDetailsPayments } from "../types"

export const DetailsPaymentsForm = ({
    detailOrder
}: IPropsDetailsPayments) => {

    const {id} = detailOrder;

    return detailOrder && (
        <DetailsPaymentsFormStyled>
            <div className="row">
                <DetailsPaymentsTitle>Детали оплаты</DetailsPaymentsTitle>
                <div className="form">
                    <Formik
                        initialValues={{
                            id: id
                        }}
                        onSubmit={() => {}}
                        
                    >
                        <>
                        <Accordion
                            isOpen={true}
                            title="Поставщик"
                            isOpenByDefault={true}
                        >
                            <div className="contentAccordionForm">
                                {ProviderData.map((row, rowIndex) => (
                                    <div className="rowForm" key={rowIndex}>
                                        {row.map((item, index) => (
                                            <FormikInput 
                                                key={index}
                                                name={item.name}
                                                label={item.label}
                                                placeholder={item.placeholder}
                                                type={item.type}
                                                disabled={item.disabled ?? false}
                                                className="inputForm"
                                                $afterIcon="clearInput"
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Accordion>
                            <Accordion
                                isOpen={false}
                                title="Покупатель"
                                isOpenByDefault={false}
                            >
                                <div className="contentAccordionForm">
                                    {Counterparty.map((row, rowIndex) => (
                                        <div className="rowForm" key={rowIndex}>
                                            {row.map((item, index) => (
                                                <FormikInput 
                                                    key={index}
                                                    name={item.name}
                                                    label={item.label}
                                                    placeholder={item.placeholder}
                                                    type={item.type}
                                                    disabled={item.disabled ?? false}
                                                    className="inputForm"
                                                    $afterIcon="clearInput"
                                                />
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </Accordion>
                            
                        <Button style={{
                            width: "100%",
                            height: "48px"
                        }}>Выставить счет</Button>
                        </>
                    </Formik>
                </div>
            </div>
        </DetailsPaymentsFormStyled>
    )
}