import styled from 'styled-components'

interface FlexProps {
    flexDirection?: 'column' | 'row'
    justifyContent?:
        | 'space-between'
        | 'space-around'
        | 'center'
        | 'start'
        | 'end'
    alignItems?: 'center' | 'start' | 'end'
    gap?: number
    padding?: number | string
    margin?: number | string
    flex?: number
    width?: number | string
    height?: number | string
    minWidth?: number | string
    minHeight?: number | string
}

export const Flex = styled.div<FlexProps>`
    display: flex;
    flex-direction: ${props => props?.flexDirection};
    justify-content: ${props => props?.justifyContent};
    align-items: ${props => props?.alignItems};
    gap: ${props => (props?.gap ? `${props?.gap}px` : undefined)};
    padding: ${props => props?.padding};
    margin: ${props => props?.margin};
    flex: ${props => props?.flex};
    width: ${props =>
        props?.width && typeof props?.width === 'number'
            ? `${props?.width}px`
            : props?.width};
    height: ${props =>
        props?.height && typeof props?.height === 'number'
            ? `${props?.height}px`
            : props?.height};
    min-width: ${props =>
        props?.minWidth && typeof props?.minWidth === 'number'
            ? `${props?.minWidth}px`
            : props?.minWidth};
    min-height: ${props =>
        props?.minHeight && typeof props?.minHeight === 'number'
            ? `${props?.minHeight}px`
            : props?.minHeight};
`
