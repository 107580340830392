import { sidebarMenuTypes } from "shared/types/sidebarMenuTypes";
import { AREASLIST, CLIENTSLIST, SETTING, SETTINGBOOKINGS, SETTINGNOTIFICATION, SETTINGPROFILE, SETTINGROLE, SETTINGTHEME } from "./routingConsts";

export const sidebarMenuData: sidebarMenuTypes[] = [
    {
        icon: "home",
        link: "/"
    },
    {
        icon: "calendar",
        link: "/calendar/day"
    },
    {
        icon: "users",
        link: CLIENTSLIST
    },
    {
        icon: "fourblock",
        link: AREASLIST
    },
    // {
    //     icon: "folder",
    //     link: "/folder"
    // },
    // {
    //     icon: "presentation",
    //     link: "/presentation"
    // },
    {
        icon: "setting",
        link: SETTING
    }
];

export const sidebardMenuSetting = [
    {
        icon: "settingProfile",
        title: "Профиль",
        link: SETTINGPROFILE,
    },
    {
        icon: "settingTheme",
        title: "Тема",
        link: SETTINGTHEME
    },
    {
        icon: "settingNotification",
        title: "Уведомления",
        link: SETTINGNOTIFICATION
    },
    {
        icon: "settingBookings",
        title: "Оплата и бронирование",
        link: SETTINGBOOKINGS
    },
    {
        icon: "settingRole",
        title: "Роли",
        link: SETTINGROLE
    }
];