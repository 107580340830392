import React from 'react'
import { useSelector } from 'react-redux'
import { BookingPreview, LoaderPreview } from 'features/OrderPreview/ui'
import { useCounterpartyAsyncAutocompleteOptions } from 'entities/counterparties/model'
import {
    selectBookingIdToEdit,
    selectBookingOriginalOrder,
} from '../../../model/slices'

export const OrderPreview = () => {
    const originalOrder = useSelector(selectBookingOriginalOrder)
    const bookingId = useSelector(selectBookingIdToEdit)

    const { counterparties, isLoading } =
        useCounterpartyAsyncAutocompleteOptions({
            search_param: originalOrder?.counterpartyEmail ?? undefined,
        })
    const currentFormCounterparty = counterparties?.find(
        counterparty => counterparty.id === originalOrder?.counterpartyId,
    )

    const currentBooking = originalOrder?.bookings?.find(
        booking => booking.id === bookingId,
    )

    if (
        !originalOrder ||
        !currentBooking ||
        !currentFormCounterparty ||
        isLoading
    ) {
        return <LoaderPreview />
    }

    return (
        <BookingPreview
            orderId={originalOrder?.id?.toString() ?? ''}
            bookingToPreview={currentBooking}
            counterparty={currentFormCounterparty}
        />
    )
}
