import { getAllPaymentsList } from 'entities/payments/api/getAllPaymentsList'
import { createCashPayment } from 'entities/payments/api/createCashPayment'
import { createOnlinePayment } from 'entities/payments/api/createOnlinePayment'
import { createCertificatePayment } from 'entities/payments/api/createCertificatePayment'
import { confirmOnlinePayment } from 'entities/payments/api/confirmOnlinePayment'
import { getPaymentsByOrderId } from 'entities/payments/api/getPaymentsByOrderId'
import { getPaymentMethods } from 'entities/payments/api/getPaymentMethods'

export const PaymentApi = {
    getAllPaymentsList,
    createCashPayment,
    createCertificatePayment,
    createOnlinePayment,
    confirmOnlinePayment,
    getPaymentsByOrderId,
    getPaymentMethods,
}
