import styled from "styled-components";

interface IPropsStyledLoader {
    width?: number;
    widthStroke?: number;
    background?: string;
}

export const StyledLoader = styled.div<IPropsStyledLoader>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
        width: ${props => props.width ? `${props.width}px` : "30px"};
        padding: ${props => props.width ? `${props.widthStroke}px` : "8px"};
        aspect-ratio: 1;
        border-radius: 50%;
        background: ${props => props.background ? props.background : "#5E81F4"};
        --_m: 
            conic-gradient(#0000 10%,#000),
            linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
                mask: var(--_m);
        -webkit-mask-composite: source-out;
                mask-composite: subtract;
        animation: l3 1s infinite linear;
        @keyframes l3 {to{transform: rotate(1turn)}}
    }
`;
