import styled from 'styled-components'

export const StyledAreaButton = styled.button<{ isActive: boolean }>`
    background: none;
    cursor: pointer;
    height: 39px;
    min-width: fit-content;
    border: 1px solid #e1e4ea;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s linear;
    gap: 10px;
    ${props =>
        props.isActive &&
        `
        border: 1px solid rgba(94, 129, 244, 0.50);
        background: linear-gradient(0deg, rgba(94, 129, 244, 0.10) 0%, rgba(94, 129, 244, 0.10) 100%),#FFF;
    `}
`

export const AutocompleteRow = styled.div`
    display: flex;
    align-items: end;
    flex-wrap: nowrap;
    gap: 10px;
`

export const StyledCrossIconContainer = styled.div`
    background: #8181a5;
    cursor: pointer;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledBookingsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 10px;
`

export const BoldText = styled.div`
    color: #282a42;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`

export const Text = styled.div`
    color: #000;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
`
