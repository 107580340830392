import { FC } from 'react'

import { OrderBooking } from 'entities/orders/model'
import { roundNumber } from 'shared/utils/roundNumber'

import { getBookingRange, getAllNomenclatureNames } from './utils'
import {
    StyledDescriptionItem,
    StyledDescriptionPrice,
    StyledFooterColumn,
    StyledFooterContainer,
    StyledFooterRow,
    StyledItemsContainer,
    StyledTitle,
} from './styles'

interface BookingTotalPricePreviewProps {
    booking: OrderBooking
}

export const BookingTotalPricePreview: FC<BookingTotalPricePreviewProps> = ({
    booking,
}) => {
    if (!booking?.totalPriceWithDiscount && !booking?.areaPrice) {
        return null
    }

    const areaPrice = roundNumber(booking?.areaPrice)

    const totalAreaPrice = roundNumber(booking.totalPriceWithDiscount ?? 0)
    const nomenclaturePrice = roundNumber(
        booking?.nomenclaturePriceWithDiscount,
    )
    const totalPrice = roundNumber(totalAreaPrice + nomenclaturePrice)

    const bookingRange = getBookingRange(booking)

    return (
        <StyledFooterContainer>
            <StyledTitle>Итого: ₽{totalPrice}</StyledTitle>

            <StyledItemsContainer>
                <StyledFooterColumn>
                    <StyledFooterRow>
                        <StyledDescriptionItem>
                            Аренда: {booking.areaName}: {bookingRange.label} (
                            {areaPrice}₽/час)
                        </StyledDescriptionItem>
                        <StyledDescriptionPrice>
                            ₽{totalAreaPrice}
                        </StyledDescriptionPrice>
                    </StyledFooterRow>
                    {Boolean(booking?.nomenclatures?.length) && (
                        <StyledFooterRow>
                            <StyledDescriptionItem>
                                Дополнительно:{' '}
                                {getAllNomenclatureNames(booking)}
                            </StyledDescriptionItem>
                            <StyledDescriptionPrice>
                                ₽{nomenclaturePrice}
                            </StyledDescriptionPrice>
                        </StyledFooterRow>
                    )}
                </StyledFooterColumn>
            </StyledItemsContainer>
        </StyledFooterContainer>
    )
}
