import { FormEvent, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from 'features/Booking/ui/components/Forms/styles'
import { closeModal, openModal } from 'shared/redux/slice/modalSlice'
import { Input } from 'shared/components/Input'
import { useCertificatesQuery } from 'entities/certificates/model/hooks'
import { AutocompleteOption } from 'shared/components/Autocomplete'

import { selectBookingCounterpartyId, selectBookingOrderId, selectIsCompanyBookingForm } from '../slices'
import { PaymentMethodName } from 'entities/payments/model'
import { DetailsPaymentsPage } from 'pages/DetailsPaymentsPage'

export const PaymentWayOption = {
    Cash: 'cash',
    Certificate: 'certificate',
    Online: 'online',
    Invoice: 'invoice'
}

export const PaymentWayOptions: AutocompleteOption[] = [
    {
        value: PaymentMethodName.Cash,
        label: 'Наличными/Картой на месте',
    },
    {
        value: PaymentMethodName.Certificate,
        label: 'Сертификат',
    },
    {
        value: 'invoice',
        label: 'Счет на оплату',
    },
    {
        value: PaymentMethodName.Online,
        label: 'Онлайн',
    },
]

type CertificateOption = AutocompleteOption<{ balance: string }>

export const usePaymentFormController = () => {
    const dispatch = useDispatch()
    const orderId = useSelector(selectBookingOrderId)
    const counterpartyId = useSelector(selectBookingCounterpartyId)
    const isCompany = useSelector(selectIsCompanyBookingForm)

    const [paymentWay, setPaymentWay] = useState<AutocompleteOption>()
    const [certificate, setCertificate] = useState<CertificateOption>()

    const [comment, setComment] = useState<string>()
    const [amount, setAmount] = useState<number>()

    const { certificates } = useCertificatesQuery(counterpartyId, {
        enabled:
            !!counterpartyId &&
            paymentWay?.value === PaymentMethodName.Certificate,
    })
    const certificatesOptions: CertificateOption[] = useMemo(
        () =>
            certificates?.map(cert => ({
                label: `${cert.name} (₽${cert.balance})`,
                value: cert.id.toString(),
                payload: {
                    balance: cert.balance.toString(),
                },
            })),
        [certificates],
    )
    const currentCertificateBalance = useMemo(
        () => certificate?.payload?.balance ?? '0',
        [certificate],
    )

    const isButtonDisabled = !paymentWay

    const onChangePaymentWay = (option: AutocompleteOption) => {
        setPaymentWay(option)
    }

    const onChangeCertificate = (option: CertificateOption) => {
        setCertificate(option)
    }

    const onChangeComment = (e: FormEvent) => {
        const newComment = (e.target as HTMLInputElement).value
        setComment(newComment)
    }

    const onEditAmount = (e: FormEvent) => {
        const newAmount = Math.abs(Number((e.target as HTMLInputElement).value))
        if (Number.isNaN(newAmount)) {
            return
        }
        setAmount(newAmount)
    }

    const onGoNext = async () => {
        if (orderId) {
            const paymentUrl = `${window.location.origin}/invoice/${orderId}`

            dispatch(
                openModal({
                    isOpen: true,
                    content: (
                        <div>
                            <Text>
                                Отправьте эту ссылку заказчику, чтобы он смог
                                оплатить
                            </Text>
                            <div />
                            <Input value={paymentUrl} disabled />
                        </div>
                    ),
                    config: {
                        title: 'Ссылка на оплату',
                        btns: [
                            {
                                onClick: () => {
                                    navigator.clipboard.writeText(paymentUrl)
                                },
                                title: 'Копировать',
                                active: true,
                            },
                            {
                                onClick: () => dispatch(closeModal()),
                                title: 'Закрыть',
                                active: false,
                            },
                        ],
                    },
                }),
            )
        }
        if(orderId && paymentWay?.value === "invoice") {
            dispatch(
                openModal({
                    isOpen: true,
                    content: (
                        <DetailsPaymentsPage />
                    ),
                    config: {
                        title: 'Ссылка на оплату',
                        btns: [
                            {
                                onClick: () => {},
                                title: 'Далее',
                                active: true,
                            },
                            {
                                onClick: () => dispatch(closeModal()),
                                title: 'Закрыть',
                                active: false,
                            },
                        ],
                    },
                }),
            )
        }
    }

    return {
        amount,
        onEditAmount,

        certificate,
        currentCertificateBalance,
        certificatesOptions,
        onChangeCertificate,
        isCompany,
        paymentWay,
        comment,
        isButtonDisabled,
        onChangePaymentWay,
        onChangeComment,
        onGoNext,
    }
}
