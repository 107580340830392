import { CheckoutPaymentsStyled, DetailsPaymentsTitle, Total, WrapperItems } from "./style"
import { IPropsDetailsPayments } from "../types"

export const CheckoutPayments = ({
    detailOrder
}: IPropsDetailsPayments) => {
    
    return (
        <CheckoutPaymentsStyled>
            <div className="row">
                <DetailsPaymentsTitle>Ваш заказ</DetailsPaymentsTitle>
                <WrapperItems>
                    {detailOrder.bookings.map((item, index) => (
                        <div key={index} className="item">
                            <span className="item-title">{item.areaName}</span>
                            <div className="item__left">
                                <div className="item-price">{item.areaPrice} ₽</div>
                                {/* <div className="item-total">₽9,000</div> */}
                            </div>
                        </div>
                    ))}
                    {/* <div className="item">
                        <span className="item-title">Помещение 1 (3ч)</span>
                        <div className="item__left">
                            <div className="item-price">₽3000</div>
                            <div className="item-total">₽9,000</div>
                        </div>
                    </div>
                    <div className="item">
                        <span className="item-title">Пепси (х3)</span>
                        <div className="item__left">
                            <div className="item-price">₽100</div>
                            <div className="item-total">₽300</div>
                        </div>
                    </div>
                    <div className="item">
                        <span className="item-title">Чипсы (х1)</span>
                        <div className="item__left">
                            <div className="item-price">₽100</div>
                            <div className="item-total">₽100</div>
                        </div>
                    </div>
                    <div className="item">
                        <span className="item-title">Скидка (10%)</span>
                        <div className="item__left">
                            <div className="item-total">-₽940</div>
                        </div>
                    </div>
                    <div className="item">
                        <span className="item-title">НДС</span>
                        <div className="item__left">
                            <div className="item-total">-</div>
                        </div>
                    </div> */}
                </WrapperItems>
                <div className="line"></div>
                <Total>
                    <div>Итого</div>
                    <div>{detailOrder.totalPrice} ₽</div>
                </Total>
            </div>
        </CheckoutPaymentsStyled>
    )
}