import { FC } from 'react'

import { getAllNomenclatureNames } from 'features/OrderPreview/ui/BookingTotalPricePreview/utils'
import { DetailOrder } from 'entities/orders/model'
import { roundNumber } from 'shared/utils/roundNumber'

import { getBookingRange } from './utils'
import {
    StyledDescriptionItem,
    StyledDescriptionPrice,
    StyledFooterColumn,
    StyledFooterContainer,
    StyledFooterRow,
    StyledItemsContainer,
    StyledTitle,
} from './styles'

interface OrderTotalPricePreviewProps {
    detailOrder: DetailOrder
}

export const OrderTotalPricePreview: FC<OrderTotalPricePreviewProps> = ({
    detailOrder,
}) => (
    <StyledFooterContainer>
        <StyledTitle>Итого: ₽{roundNumber(detailOrder.totalPrice)}</StyledTitle>

        <StyledItemsContainer>
            {detailOrder.bookings.map((booking, index) => (
                <StyledFooterColumn key={index}>
                    <StyledFooterRow>
                        <StyledDescriptionItem>
                            Аренда: {booking.areaName}:{' '}
                            {getBookingRange(booking)}
                        </StyledDescriptionItem>
                        <StyledDescriptionPrice>
                            ₽{roundNumber(booking?.areaPrice)}
                        </StyledDescriptionPrice>
                    </StyledFooterRow>
                    {Boolean(booking?.nomenclatures?.length) && (
                        <StyledFooterRow>
                            <StyledDescriptionItem>
                                Дополнительно:{' '}
                                {getAllNomenclatureNames(booking)}
                            </StyledDescriptionItem>
                            <StyledDescriptionPrice>
                                ₽
                                {roundNumber(
                                    booking?.nomenclaturePriceWithDiscount,
                                )}
                            </StyledDescriptionPrice>
                        </StyledFooterRow>
                    )}
                </StyledFooterColumn>
            ))}
        </StyledItemsContainer>
    </StyledFooterContainer>
)
