import { SettingThemeList, SettingThemeWrapper } from "./style"

const themeColor = ["#5E81F4", "#FFD400", "#FF4747", "#7856FF", "#FF7A00", "#00BA7C"];

export const SettingTheme = () => {
    return (
        <SettingThemeWrapper>
            <div className="title">Основной цвет</div>
            <SettingThemeList>
                {themeColor.map((item, index) => (
                    <div key={index} className="block" style={{backgroundColor: item}}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 7.00244L9.42857 17.0024L6 13.0024" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                ))}
            </SettingThemeList>
        </SettingThemeWrapper>
    )
}