import { ThemeProvider } from "styled-components"
import { FC } from "react"
import { GlobalStyle } from "shared/styles/GlobalStyle"
import { theme } from "shared/styles/theme"

interface IProviders {
    children: JSX.Element
}

export const Providers: FC<IProviders> = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            {children}
        </ThemeProvider>
    )
}