import moment from 'moment'
import { useParams } from 'react-router-dom'

import { useGetPaymentsByOrderIdQuery } from 'entities/payments/model'
import { useGetDetailOrderQuery } from 'entities/orders/model'
import { OrdersApi } from 'entities/orders/api'
import { Line } from 'shared/components/Line'
import { Flex } from 'shared/components/Flex'
import { Button } from 'shared/components/Button'

import {
    InvoiceDetailsTable,
    InvoiceHeader,
    InvoiceSubHeader,
    InvoiceDetailsOverview,
    InvoicePaymentsTable,
} from './components'
import {
    StyledContainer,
    StyledErrorText,
    StyledHeaderTitle,
} from './components/styles'

export const InvoicePage = () => {
    const { id } = useParams()
    const orderId = Number(id)
    const { detailOrder } = useGetDetailOrderQuery(orderId)
    const { payments } = useGetPaymentsByOrderIdQuery(orderId)

    if (!id || !detailOrder) {
        return <div>Заказ не найден</div>
    }

    const createdAt = moment(detailOrder.createdAt).format('DD.MM.YYYY HH:MM')

    const pay = async () => {
        if (orderId) {
            const { paymentUrl } = await OrdersApi.getOrderPaymentUrl({
                orderId,
                redirectUrl: window.location.href,
            })
            window.open(paymentUrl, '_blank')
        }
    }

    return (
        <Flex justifyContent='center' minHeight='100vh'>
            <StyledContainer>
                <InvoiceHeader id={id} createdAt={createdAt} />
                <InvoiceSubHeader />
                <Line />

                <InvoiceDetailsOverview detailOrder={detailOrder} />
                <Flex flexDirection='column' gap={10}>
                    <StyledHeaderTitle>Смета</StyledHeaderTitle>
                    <InvoiceDetailsTable bookings={detailOrder.bookings} />
                </Flex>
                <Flex justifyContent='end' flex={1}>
                    <StyledHeaderTitle>
                        Итого: {detailOrder.totalPrice}
                    </StyledHeaderTitle>
                </Flex>
                <br />
                {payments?.length > 0 && (
                    <InvoicePaymentsTable payments={payments ?? []} />
                )}
                <Flex alignItems='center' justifyContent='space-between'>
                    <Button themeType='primary' onClick={pay}>
                        Оплатить онлайн
                    </Button>
                    <Flex flexDirection='column' gap={5}>
                        <StyledHeaderTitle>
                            Итого: {detailOrder.totalPrice}
                        </StyledHeaderTitle>
                        <StyledErrorText>Не оплачено</StyledErrorText>
                    </Flex>
                </Flex>
            </StyledContainer>
        </Flex>
    )
}
