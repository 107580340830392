import { PayloadAction } from '@reduxjs/toolkit'
import { DetailOrder, Nomenclature, OrderBooking } from 'entities/orders/model'
import { initialState } from './bookingSidebarForm'
import {
    ActiveBookingFormTab,
    BookingModeType,
    BookingState,
    BookingStepType,
    OrderViewModeType,
} from './types'
import { ViewModeType } from '../../../BookingCalendarController/model'

export const addBookingAction = (
    state: BookingState,
    action: PayloadAction<OrderBooking>,
) => {
    state.bookings = [...state.bookings, action.payload]
}

export const addEmptyBookingAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number }>,
) => {
    state.bookings = [
        ...state.bookings,
        {
            id: action.payload.bookingId,
            areaPrice: 0,
            comment: '',
            membersCount: null,
            nomenclatures: [],
            nomenclaturePrice: 0,
            nomenclaturePriceWithDiscount: 0,
            areaAbsolutDiscount: null,
            areaPercentDiscount: null,
            totalPrice: 0,
            totalPriceWithDiscount: 0,
            areaId: null,
            areaName: null,
            startTime: null,
            endTime: null,
        },
    ]
}

export const removeBookingAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number }>,
) => {
    state.bookings = state.bookings.filter(
        booking => booking.id !== action.payload.bookingId,
    )
}

export const editBookingAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number; body: any }>,
) => {
    state.bookings = state.bookings.map(booking => {
        if (booking.id === action.payload.bookingId) {
            return {
                ...booking,
                ...action.payload.body,
                id: booking.id,
            }
        }
        return booking
    })
}

export const setActiveBookingIdAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number | null }>,
) => {
    state.activeBookingId = action.payload.bookingId
}

export const setBookingIdToEditAction = (
    state: BookingState,
    action: PayloadAction<{ bookingIdToEdit: number }>,
) => {
    state.bookingIdToEdit = action.payload.bookingIdToEdit
}

export const setBookingCurrentStepAction = (
    state: BookingState,
    action: PayloadAction<{ step: BookingStepType }>,
) => {
    state.currentStep = action.payload.step
}

export const setBookingCounterpartyIdAction = (
    state: BookingState,
    action: PayloadAction<{ id: number | null }>,
) => {
    state.counterpartyId = action.payload.id
}

export const setBookingCounterpartyNameAction = (
    state: BookingState,
    action: PayloadAction<{ name: string | null }>,
) => {
    state.counterpartyName = action.payload.name
}

export const setBookingOrderIdAction = (
    state: BookingState,
    action: PayloadAction<{ orderId: number }>,
) => {
    state.orderId = action.payload.orderId
}

const BlackListToCleanup = ['view', 'today']
export const clearBookingStateAction = (state: BookingState) => {
    for (const key in initialState) {
        if (key in state) {
            if (BlackListToCleanup.includes(key)) {
                continue
            }
            // @ts-expect-error safe operation / temp fix
            state[key as keyof BookingState] =
                initialState[key as keyof typeof initialState]
        }
    }
}

export const setMembersCountToBookingAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number; newMembersCount: number }>,
) => {
    state.bookings = state.bookings.map(booking => {
        if (booking.id === action.payload.bookingId) {
            return {
                ...booking,
                membersCount: action.payload.newMembersCount,
            }
        }
        return booking
    })
}

export const setCommentToBookingAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number; newComment: string }>,
) => {
    state.bookings = state.bookings.map(booking => {
        if (booking.id === action.payload.bookingId) {
            return {
                ...booking,
                comment: action.payload.newComment,
            }
        }
        return booking
    })
}

export const setBookingFormModeAction = (
    state: BookingState,
    action: PayloadAction<{ mode: BookingModeType }>,
) => {
    state.mode = action.payload.mode
}

export const setBookingFormOrderViewModeAction = (
    state: BookingState,
    action: PayloadAction<{ orderViewMode: OrderViewModeType }>,
) => {
    state.orderViewMode = action.payload.orderViewMode
}

export const setBookingsAction = (
    state: BookingState,
    action: PayloadAction<{ bookings: OrderBooking[] }>,
) => {
    state.bookings = action.payload.bookings
}

export const replaceBookingTempClientIdToOriginIdAction = (
    state: BookingState,
    action: PayloadAction<{ oldId: number; newId: number }>,
) => {
    state.bookings = state.bookings.map(booking => {
        if (booking.id === action.payload.oldId) {
            return {
                ...booking,
                id: action.payload.newId,
            }
        }
        return booking
    })
}

export const setOriginalOrderAction = (
    state: BookingState,
    action: PayloadAction<{ order: DetailOrder }>,
) => {
    state.originalOrder = action.payload.order
}

export const addNomenclatureToBookingAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number; nomenclature: Nomenclature }>,
) => {
    state.bookings = state.bookings.map(booking => {
        if (booking.id === action.payload.bookingId) {
            return {
                ...booking,
                nomenclatures: [
                    ...(booking.nomenclatures ?? []),
                    action.payload.nomenclature,
                ],
            }
        }
        return booking
    })
}

export const setOrderTotalPriceAction = (
    state: BookingState,
    action: PayloadAction<{ price: number | null }>,
) => {
    state.totalPrice = action.payload.price
}

export const removeNomenclatureToBookingAction = (
    state: BookingState,
    action: PayloadAction<{ bookingId: number; nomenclatureId: number }>,
) => {
    state.bookings = state.bookings.map(booking => {
        if (booking.id === action.payload.bookingId) {
            return {
                ...booking,
                nomenclatures: (booking.nomenclatures ?? []).filter(
                    nomenclature =>
                        nomenclature.id !== action.payload.nomenclatureId,
                ),
            }
        }
        return booking
    })
}

export const setBookingActiveTabAction = (
    state: BookingState,
    action: PayloadAction<{ tab: ActiveBookingFormTab }>,
) => {
    state.bookingActiveTab = action.payload.tab
}

export const setOrderEditStateInitialisingAction = (
    state: BookingState,
    action: PayloadAction<{ isInitialising: boolean }>,
) => {
    state.isEditStateInitialising = action.payload.isInitialising
}

export const setTodayAction = (
    state: BookingState,
    action: PayloadAction<{ today: string }>,
) => {
    state.today = action.payload.today
}

export const setViewAction = (
    state: BookingState,
    action: PayloadAction<{ view: ViewModeType }>,
) => {
    state.view = action.payload.view
}

export const setIsDirtyAction = (
    state: BookingState,
    action: PayloadAction<{ isDirty: boolean }>,
) => {
    state.isDirty = action.payload.isDirty
}

export const setIsCompanyAction = (
    state: BookingState,
    action: PayloadAction<{ isCompany: boolean }>,
) => {
    state.isCompany = action.payload.isCompany
}
