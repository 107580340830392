import styled from 'styled-components'

export const StyledContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
    background: #fff;
    gap: 20px;
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`

export const StyledSubHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;
`

export const StyledHeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #282a42;
`

export const StyledHeaderRegularTitle = styled.span`
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #282a42;
`

export const StyledSecondaryText = styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    color: #8181a5;
`

export const StyledSecondaryLinkText = styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-decoration: underline;
    cursor: pointer;
    color: #8181a5;
`

export const StyledCompanyImage = styled.img`
    width: 85px;
    height: 85px;
    border-radius: 50%;
`

export const StyledTable = styled.table`
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
`

export const StyledTableHeader = styled.th`
    background: #e1e4ea;
    padding: 10px;
    color: #8181a5;
    &:first-child {
        border-radius: 16px 0 0 0;
    }
    &:last-child {
        border-radius: 0 16px 0 0;
    }
`

export const StyledTableCell = styled.td`
    padding: 5px;
    text-align: left;
`

export const StyledNestedTableCell = styled(StyledTableCell)`
    padding-left: 25px;
    color: #555;
`

export const StyledTableRow = styled.tr`
    background-color: #fff;
`

export const StyledNestedTableRow = styled(StyledTableRow)`
    background-color: #f9f9f9;
`

export const StyledErrorText = styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: right;
    color: #ed4627;
`
