import React from 'react'

import { Tab, Tabs } from 'shared/components/Tabs'
import { Accordion } from 'shared/components/Accordion'
import { Header, GenericInfoForm, BookingForm, PaymentForm } from './components'
import { BookingSidebarContainer } from './styles'
import { useBookingSidebarController } from '../model/hooks'
import { Footer } from './components/Footer'
import { OrderPreview } from './components/Preview/OrderPreview'

export const BookingSidebar = () => {
    const {
        AccordionState,
        activeTabIndex,
        disabledTabIndex,
        isOpen,
        isPreview,
        isEdit,
        goFromPreviewToEdit,
        onChangeTab,
        handleCloseSidebar,
        onDeleteOrder,
        onRemoveBooking,
    } = useBookingSidebarController()

    return (
        <BookingSidebarContainer isexpanded={isOpen}>
            <div
                className='sibedar-top-row'
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'auto',
                }}
            >
                <Header
                    onRemoveBooking={onRemoveBooking}
                    isPreview={isPreview}
                    goFromPreviewToEdit={goFromPreviewToEdit}
                    onDeleteOrder={onDeleteOrder}
                    handleCloseSidebar={handleCloseSidebar}
                />
                <div className='line'></div>
                {isPreview ? (
                    <OrderPreview />
                ) : (
                    <>
                        <div style={{ flex: 1, width: '100%' }}>
                            <Tabs
                                activeTabIndex={activeTabIndex}
                                disabledTabIndex={disabledTabIndex}
                                onTabChange={onChangeTab}
                            >
                                <Tab label='Физическое лицо'>
                                    <Accordion
                                        isOpen={
                                            AccordionState.GeneralInfo.isOpen
                                        }
                                        title={AccordionState.GeneralInfo.title}
                                        isOpenByDefault={!isEdit}
                                    >
                                        <GenericInfoForm />
                                    </Accordion>
                                    <Accordion
                                        isOpen={AccordionState.Booking.isOpen}
                                        title={AccordionState.Booking.title}
                                        disabled={
                                            AccordionState.Booking.isDisabled
                                        }
                                        isOpenByDefault={isEdit}
                                    >
                                        <BookingForm />
                                    </Accordion>
                                    <Accordion
                                        isOpen={AccordionState.Payment.isOpen}
                                        title={AccordionState.Payment.title}
                                        disabled={
                                            AccordionState.Payment.isDisabled
                                        }
                                    >
                                        <PaymentForm />
                                    </Accordion>
                                    <Accordion
                                        isOpen={AccordionState.Documents.isOpen}
                                        title={AccordionState.Documents.title}
                                        disabled={
                                            AccordionState.Documents.isDisabled
                                        }
                                    >
                                        {AccordionState.Documents.title}
                                    </Accordion>
                                </Tab>
                                <Tab label='Юридическое лицо'>
                                    <Accordion
                                        isOpen={
                                            AccordionState.GeneralInfo.isOpen
                                        }
                                        title={AccordionState.GeneralInfo.title}
                                        isOpenByDefault
                                    >
                                        <GenericInfoForm isCompany />
                                    </Accordion>
                                    <Accordion
                                        isOpen={AccordionState.Booking.isOpen}
                                        title={AccordionState.Booking.title}
                                        disabled={
                                            AccordionState.Booking.isDisabled
                                        }
                                    >
                                        <BookingForm />
                                    </Accordion>
                                    <Accordion
                                        isOpen={AccordionState.Payment.isOpen}
                                        title={AccordionState.Payment.title}
                                        disabled={
                                            AccordionState.Payment.isDisabled
                                        }
                                    >
                                        <PaymentForm />
                                    </Accordion>
                                    <Accordion
                                        isOpen={AccordionState.Documents.isOpen}
                                        title={AccordionState.Documents.title}
                                        disabled={
                                            AccordionState.Documents.isDisabled
                                        }
                                    >
                                        {AccordionState.Documents.title}
                                    </Accordion>
                                </Tab>
                            </Tabs>
                        </div>
                        <Footer />
                    </>
                )}
            </div>
        </BookingSidebarContainer>
    )
}
