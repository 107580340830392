import styled from "styled-components";

export const WrapperModal = styled.div`
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`

export const WrapperModalContent = styled.div`
    border-radius: 16px;
    background: #fff;
    padding: 20px;
    width: 100%;
    max-width: 1000px;
    &.modal-litle-size {
        max-width: 407px;
    }
    .modalcontent__btns {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        button {
            width: 100%;
        }
        &--outline {
            border: 1px solid #5e81f4;
            background: none;
            span {
                color: #5e81f4;
            }
        }
    }
    .blockWithComment {
        display: flex;
        flex-direction: column;
    }
    textarea {
        margin-top: 10px;
        border-radius: 4px;
        border: 1px solid #e1e4ea;
        padding: 10px;
        height: 70px;
        color: #8181a5;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        resize: none;
    }
`

export const ModalContentTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e4ea;
    margin-bottom: 10px;
    .modal-title {
        color: #1c1c1c;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
`

export const ModalClose = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
`

export const WrapperStyled = styled.div`
    user-select: none;
    height: 600px;
    .swiper {
        height: 100%;
        display: flex;
        justify-content: center;
        .swiper-slide {
            overflow: hidden;
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            margin: 0 auto;
            /* aspect-ratio: auto; */
        }
    }
    .swiper-arrow {
        border-radius: 8px;
        border: 0.848px solid #E1E4EA;
        background: #fff;
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 9999;
        svg {
            width: 24px;
            height: 24px;
            path {
                stroke: #8181A5;
            }
        }
    }
    .swiper-button-next {
        right: 15px;
    }
    .swiper-button-prev {
        left: 15px;
    }
    .swiper-button-disabled {
        display: none;
    }

`